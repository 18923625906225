import { InjectionToken } from '@angular/core';
import { Request, Response } from 'express';

export const IS_SERVER: InjectionToken<boolean> = new InjectionToken('isServer');
export const REAL_IP: InjectionToken<string> = new InjectionToken('realIp');
export const REQUEST = new InjectionToken<Request>('REQUEST');
export const RESPONSE = new InjectionToken<Response>('RESPONSE');

export const WINDOW: InjectionToken<Window> = new InjectionToken('window');
export const WINDOW_ID: InjectionToken<string> = new InjectionToken('');
export const BUILDVERSION: InjectionToken<{ timestamp: number; version: string }> = new InjectionToken(
  'buildVersion'
);
export const USER_ID: InjectionToken<number> = new InjectionToken('userId');
